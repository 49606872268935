.editor {
  position: relative;
  display: grid;
  grid-template-rows: auto 1fr;
}

.editor-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--bg-dark);
  padding: 0 5px;
}

.editor-actions {
  position: absolute;
  top: 5px;
  right: 0;
}

.editor-actions>button {
  background-color: transparent;
  border: none;
  color: var(--secondary);
  font-size: 14px;
  padding: 5px;
}

.editor-actions>button:hover {
  color: var(--primary);
}

.editor-files {
  display: flex;
}

.editor-file {
  position: relative;
  display: flex;
  padding: 0 5px;
  gap: 5px;
  background-color: var(--bg-secondary);
  color: var(--secondary);
  border: 1px solid var(--bg-dark);
  border-bottom: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.editor-file>.editor-filename {
  font-size: 14px;
  height: 26px;
  line-height: 26px;
}

.editor-file.active {
  color: var(--primary);
  background-color: var(--bg-primary);
  border-color: var(--bg-dark);
  border-bottom-color: transparent;
}

.editor-file.active::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  bottom: -2px;
  left: 0;
  background-color: var(--bg-primary);
}

.editor-file>button {
  background: transparent;
  border: none;
  padding: 0;
}

.editor-file>button.editor-switch {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.editor-file>button.editor-close {
  color: var(--secondary);
  font-weight: bold;
  z-index: 1;
}

.editor-file>button.editor-close:hover {
  color: var(--primary);
}

.editor-container {
  min-width: 0;
  min-height: 0;
}

.cm-editor {
  border: none;
  outline: none;
  height: 100%;
  font-size: 16px;
}

.cm-editor.cm-focused {
  border: none;
  outline: none;
}
