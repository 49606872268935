body {
  padding: 0;
  margin: 0;
  font-family: sans-serif;
}

:root {
  --bg-primary: #FFF;
  --bg-secondary: #EEE;
  --bg-dark: #AAA;
  --primary: #111;
  --secondary: #777;
  --disabled: #AAA;
}

.repl {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(6, 1fr);
  width: 100vw;
  height: 100vh;
}

.editor {
  grid-row: 1 / 5;
  grid-column: 1;
  overflow: auto;
  border-style: solid;
  border-color: var(--bg-secondary);
  border-width: 0px 2px 2px 0px;
  padding-top: 5px;
}

.term {
  grid-row-start: 5;
  grid-row-end: -1;
  grid-column: 1;
  border-style: solid;
  background-color: var(--bg-primary);
  border-color: var(--bg-secondary);
  border-width: 2px 2px 0px 0px;
  overflow: hidden;
  padding: 0px 5px;
}

.editor[style*='display: none']~.term {
  grid-row-start: 1;
}

.files {
  grid-row: 1 / 3;
  grid-column: 2;
  border-style: solid;
  border-color: var(--bg-secondary);
  border-width: 0px 0px 2px 2px;
  overflow: auto;
  padding: 5px 0;
}

.plot {
  grid-row: 3 / -1;
  grid-column: 2;
  border-style: solid;
  border-color: var(--bg-secondary);
  border-width: 2px 0px 0px 2px;
  overflow: hidden;
}
